<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <TableHeader />
        <template v-if="!dataListRhpp.isLoading">
          <tbody v-if="dataListRhpp.meta.total_count > 0">
            <tr
              v-for="(item, i) in dataListRhpp.data"
              :key="i"
            >
              <td>{{ item.number || "-" }}</td>
              <td>{{ item.log_cycle_code || "-" }}</td>
              <td>{{ item.partner_name || "-" }}</td>
              <td>{{ item.cage_name || "-" }}</td>
              <td>{{ item.cycle_count || "-" }}</td>
              <td>{{ formatDate(item.chick_out) || "-" }}</td>
              <td>{{ formatDate(item.created_at) || "-" }}</td>
              <td>{{ formatDate(item.updated_at) || "-" }}</td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.approval_status_data_analyst == 1 ? 'checklist' : 'close-red'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.approval_status_head_branch == 1 ? 'checklist' : 'close-red'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.approval_status_partner == 1 ? 'checklist' : 'close-red'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${ item.delivery_order_doc == 'Belum' || item.delivery_order_doc.length < 1 ? 'close-red' : 'checklist'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.delivery_order_ovk == 'Belum' || item.delivery_order_ovk.length < 1 ? 'close-red' : 'checklist'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.delivery_order_feed == 'Belum' || item.delivery_order_feed.length < 1 ? 'close-red' : 'checklist'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.weighing_data ? 'checklist' : 'close-red'}.svg`"
                  alt="icon check"
                >
              </td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Peternakan`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt="icon delete"
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(item)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Peternakan`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="showEdit(item.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Peternakan`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt="icon view"
                  class="uk-margin-small-left img-click"
                  @click="showDetail(item.id, item.log_cycle_code)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="14"
          />
        </template>
        <template v-else>
          <loading-table :colspan="16" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="dataListRhpp.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { dateUtcParanjeString } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import TableHeader from "./tableHeader"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    TableHeader
  },
  data() {
    return {
      status: "",
      images: PREFIX_IMAGE,
      dataListRhpp: {
        data: [],
        meta: {},
        isLoading: true
      }
    }
  },
  computed: {
    ...mapGetters({
      listRhpp: "rhpp/list",
      meta: "rhpp/meta"
    })
  },
  watch: {
    async meta() {
      this.dataListRhpp.isLoading = true
      await this.getListRhpp(this.meta)
      this.dataListRhpp = {
        data: this.listRhpp.data || [],
        meta: this.listRhpp.meta || {},
        isLoading: false
      }
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      ["order_by"]: "number",
      ["sort_by"]: "DESC"
    })
    await this.getListRhpp(this.meta)
    this.dataListRhpp = {
      data: this.listRhpp.data || [],
      meta: this.listRhpp.meta || {},
      isLoading: false
    }
  },
  methods: {
    clearedImgSrc(val) {
      this.imgSrc = val
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getListRhpp: "rhpp/getListRhpp"
    }),
    ...mapMutations({
      setMeta: "rhpp/SET_META",
      setModalDelete: "rhpp/SET_MODAL_DELETE"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showEdit(id) {
      this.$router.push(`/admin/rhpp/edit/${id}`)
    },
    showDetail(id, cycle_id) {
      this.$router.push(`/admin/rhpp/detail/${id}/${cycle_id}`)
    },
    showDeleteConfirmModal(data) {
      window.UIkit.modal("#modal-delete-confirm").show()
      this.setModalDelete(data)
    }
  }
}
</script>
